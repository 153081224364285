import {Switch,Route,Redirect} from 'react-router-dom'
import React,{useState} from 'react'
import Layout1 from '../layouts/backend/layout1'
import BlankLayout from '../layouts/blanklayout';
import Login from '../views/backend/pages/auth/login';
import RecoverPswd from '../views/backend/pages/auth/recover-pswd';


const LayoutsRoute = props => {
    
    const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem('isLoggedIn') === 'true');
    
    return (
        <Switch>
            <Route path="/extra-pages" component={BlankLayout} />
            <Route path="/recuperarPassword">
                {isLoggedIn ? <Redirect to="/" /> : <RecoverPswd />}
            </Route>
            <Route path="/login">
                {isLoggedIn ? <Redirect to="/" /> : <Login setIsLoggedIn={setIsLoggedIn} />}
            </Route>
            <Route path="/" render={() => (isLoggedIn ? <Layout1 /> : <Redirect to="/login" />)}
        />
        </Switch>
    )
}

export default LayoutsRoute