import React,{useState,useEffect} from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory ,Link} from 'react-router-dom'
//axios
import axios from 'axios'
//prime message
import { Message } from 'primereact/message';

const RecoverPswd = (props) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    let history = useHistory()
    const [show, setShow] = useState(false);

    const handleReset = () => {
        if(!email){
            setError('Por favor, introduce tu email');
        } else {
            //comprobamos si es un email valido
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(!regex.test(email)){
                setError('El email no es válido');
            } else {
            
                axios
                    .post('http://localhost:8080/webappacademia/apprest/resetPassword', { email: email})
                    .then(response => {
                        if (response.data.success) {
                            setMessage('Se ha enviado un correo electrónico a la dirección indicada con las instrucciones para restablecer tu contraseña');
                            setError('');
                        } else {
                            setError(response.data.message);
                            setEmail('');
                        }
                    })
                    .catch(error => {
                        setError(error.message);
                    });
            }
        }
    };

    return (
        <>
            <section className="sign-in-page">
                <Container>
                    <Row className="row justify-content-center align-items-center height-self-center">
                        <Col lg="5" md="12" className="align-self-center">
                            <div className="sign-user_card ">                    
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <h3 className="mb-3 text-center">Recordar contraseña</h3>
                                        <p className="text-body">Introduce tu email y te enviaremos un correo electrónico con instrucciones para restablecer tu contraseña.</p>
                                        <Form className="mt-4">
                                            <div className="form-group">                                 
                                            <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control mb-0" id="exampleInputEmail1" placeholder="Email" autoComplete="off" required/>
                                            </div>                           
                                            <div className="sign-info">
                                                <Button className="btn btn-hover btn-primary1" onClick={handleReset}>Enviar</Button>
                                                {error && (
                                                <Message severity="error" text={error} />
                                                )}                                                            
                                            </div>                                       
                                        </Form>
                                    </div>
                                </div>                    
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default RecoverPswd