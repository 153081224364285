import React,{ useState,useRef} from 'react'
import {Container,Row,Col,OverlayTrigger,Tooltip} from 'react-bootstrap'
import Card  from '../../../components/Card'
//axios
import axios from 'axios'
import api from '../../../interceptor/axios';
//skeleton
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';   
import { Dialog } from 'primereact/dialog'; 
import { Knob } from 'primereact/knob';   
import { ListBox } from 'primereact/listbox';    
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';

import cabecera from '../../../assets/images/tests.jpg'

const ConfiguradorTest = ({minPreguntas, maxPreguntas, stepsPreguntas, tiempo, permitirAyuda, onNumPreguntasChange, onMetodoCorreccionChange, onTiempoChange, onAyudaChange, onSinTiempoChange}) => {

    
    const metodosDeCorreccion = [
        { name: 'Selecciona un método de corrección', code: '' },
        { name: '2-1', code: '2-1' },
        { name: '3-1', code: '3-1' },
        { name: '4-1', code: '4-1' }
    ];

    const [numPreguntasTestPersonalizado, setNumPreguntasTestPersonalizado] = useState(minPreguntas);
    const [metodoCorreccion, setMetodoCorreccion] = useState(metodosDeCorreccion[0]);
    const [tiempoMinutos, setTiempoMinutos] = useState(tiempo !== null ? tiempo : 30);
    const [sinTiempo, setSinTiempo] = useState(false);
    const [conAyuda, setConAyuda] = useState(false);

    const onCambioNumPreguntas = (value) => {
        setNumPreguntasTestPersonalizado(value);
        onNumPreguntasChange(value);
    };

    const onCambioSinTiempo = (value) => {
        setSinTiempo(value);
        onSinTiempoChange(value);
    };

    const onCambioTiempo = (value) => {
        setTiempoMinutos(value);
        onTiempoChange(value);
    };

    const onCambioMetodoCorreccion = (value) => {
        setMetodoCorreccion(value);
        onMetodoCorreccionChange(value);
    };

    const onCambioAyuda = (value) => {
        setConAyuda(value);
        onAyudaChange(value);
    };

        return (
            <>
                <div className='d-flex justify-content-around'>
                    <div className='form-group'>
                        <label>Nº de preguntas</label>
                        <Knob value={numPreguntasTestPersonalizado} onChange={(e) => onCambioNumPreguntas(e.value)} min={minPreguntas} max={maxPreguntas} step={stepsPreguntas} />
                    </div>
                    <div className='form-group'>
                        <label>Tiempo (minutos)</label>
                        <Knob disabled={sinTiempo} value={tiempoMinutos} onChange={(e) => onCambioTiempo(e.value)} min={tiempo !== null ? tiempo : 30} max={tiempo !== null ? tiempo : 120} step={stepsPreguntas} />
                        <label>Sin tiempo</label>
                        <br />
                        <Checkbox onChange={e => onCambioSinTiempo(e.checked)} checked={sinTiempo}></Checkbox>
                    </div>
                </div>                
                
                    {permitirAyuda && (
                    <div className='form-group w-100'>
                        <label>Ayuda en tiempo real</label>
                        <br />
                        <Checkbox onChange={e => onCambioAyuda(e.checked)} checked={conAyuda}></Checkbox>
                        <div><small>Si se activa, antes de cambiar de pregunta podrás comprobar si está correcta o no</small></div>
                    </div>)}
                    <div className='form-group w-100'>
                        <label>Método de corrección</label>
                        <Dropdown value={metodoCorreccion} onChange={(e) => onCambioMetodoCorreccion(e.value)} options={metodosDeCorreccion} optionLabel="name" 
                            placeholder="Selecciona un método de corrección" className="w-100 mt-4 mb-4" />
                    </div>
                
            </>
        );
};

const ListaTests = () => { 
       
    const restUrlBase = process.env.REACT_APP_REST_URL;
    const toast = useRef(null);
    const [listaTests, setListaTests] = useState(null);
    const [listaControles, setListaControles] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalPersonalizarVisible, setModalpersonalizarVisible] = useState(false);
    const [tituloModalPersonalizado, setTituloModalPersonalizado] = useState('');
    const [tipoTestPersonalizado, setTipoTestPersonalizado] = useState('');
    const [gruposTematicos, setGruposTematicos] = useState([]);
    const [grupoTematico, setGrupoTematico] = useState(null);
    const [temas, setTemas] = useState([]);
    const [vistaSeleccionTemas, setVistaSeleccionTemas] = useState(false);
    const [temasSeleccionados, setTemasSeleccionados] = useState([]);
    const [numPreguntasTestPersonalizado, setNumPreguntasTestPersonalizado] = useState(10);
    const [tiempoTest, setTiempoTest] = useState(30);
    const [sinTiempo, setSinTiempo] = useState(false);
    const [modoAyuda, setModoAyuda] = useState(false);
    const [metodoCorreccion, setMetodoCorreccion] = useState('3-1');
    const [contadorPreguntasFalladas, setContadorPreguntasFalladas] = useState(0);
    const [minPreguntasTestPersonalizado, setMinPreguntasTestPersonalizado] = useState(10);
    const [maxPreguntasTestPersonalizado, setMaxPreguntasTestPersonalizado] = useState(100);

    const onTemasSeleccionadosChange = (e) => {
        let temas = [...temasSeleccionados];

        if (e.checked)
            temas.push(e.value);
        else
            temas.splice(temas.indexOf(e.value), 1);

        setTemasSeleccionados(temas);
    }

    const metodosDeCorreccion = [
        { name: 'Selecciona un método de corrección', code: '' },
        { name: '2-1', code: '2-1' },
        { name: '3-1', code: '3-1' },
        { name: '4-1', code: '4-1' }
    ];

    const irATest = (test) => {
        //añadimos la marca de inicio al test
        test.fechaInicio = new Date().getTime();
        sessionStorage.setItem('test', JSON.stringify(test));
        window.location.href = '/test';
    };

    const hacerControl = (control) => {
        setLoading(true);
        api.post(restUrlBase + "test/control.php", { idControl : control.ID })
        .then(response => {
            setLoading(false);

            if (response.data.success) {
                irATest(response.data.test);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
            }
        })
        .catch(error => {
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        });   
      };

      const hacer = (test) => {
        setLoading(true);
        api.post(restUrlBase + "test/test.php", { idTest : test.ID })
        .then(response => {
            setLoading(false);

            if (response.data.success) {
                irATest(response.data.test);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
            }
            
        })
        .catch(error => {
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        });        
      };

      const tratarResponseTestPersonalizado = (response) => {
        setLoading(false);
        if (response.data.success) {
            let test = response.data.test;
            if(test.preguntas.length === 0){
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'No hay preguntas que cumplan los requisitos seleccionados para hacer el test' });
                return;
            }
            test.modoAyuda = modoAyuda;
            test.metodoCorreccion = metodoCorreccion.code;
            if(sinTiempo == false){
                test.tiempo = tiempoTest;            
            }

            irATest(test);
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
        }
      };

      const personalizar = () => {

        if(metodoCorreccion === ''){
            toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Debe seleccionar un método de corrección' });
            return;
        }
        if(tipoTestPersonalizado === 'g'){
            if(grupoTematico === null){
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Debe seleccionar un grupo temático' });
                return;
            }
            setLoading(true);
            api.post(restUrlBase + "test/testGrupoTematico.php", { idGrupo: grupoTematico.ID, numPreguntas: numPreguntasTestPersonalizado })
            .then(response => {
                tratarResponseTestPersonalizado(response);
            }).catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            }); 
        } else if(tipoTestPersonalizado === 't'){
            if(temasSeleccionados.length === 0){
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Debe seleccionar al menos un tema' });
                return;
            }
            setLoading(true);
            api.post(restUrlBase + "test/testTemas.php", { temas: temasSeleccionados, numPreguntas: numPreguntasTestPersonalizado })
            .then(response => {
                tratarResponseTestPersonalizado(response);
            }).catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            });
        } else if(tipoTestPersonalizado === 'r'){
            setLoading(true);
            api.post(restUrlBase + "test/testAleatorio.php", { numPreguntas: numPreguntasTestPersonalizado })
            .then(response => {
                tratarResponseTestPersonalizado(response);
            }).catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            });
        } else if(tipoTestPersonalizado === 'e'){
            if(contadorPreguntasFalladas === 0){
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Actualmente no tienes preguntas falladas' });
                return;
            }
            setLoading(true);
            api.post(restUrlBase + "test/testErrores.php", { numPreguntas: numPreguntasTestPersonalizado })
            .then(response => {
                tratarResponseTestPersonalizado(response);
            }).catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            });
        }

      };

      
      
      const descargar = (test) => {
        //sacamos el id usuario del token jwt
        const token = sessionStorage.getItem('token');

        axios.post(restUrlBase + "descargarPDF.php", { id: test.ID, tipo: 'T' }, { responseType: 'arraybuffer', headers: {
            'Authorization': 'Bearer ' + token} 
        })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
      
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'test.pdf');
      
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
          });
      };
      

      const volverVistaGeneral = () => {
        setVistaSeleccionTemas(false);
      };

      const testGrupoTematico = () => {
        setTituloModalPersonalizado('Test por grupo temático');
        setTipoTestPersonalizado('g');
        setGrupoTematico(null);
        setMetodoCorreccion('');
        setMinPreguntasTestPersonalizado(10);
        setNumPreguntasTestPersonalizado(10);
        setMaxPreguntasTestPersonalizado(100);
        setModalpersonalizarVisible(true);
      };

      const testTemas = () => {
        setTituloModalPersonalizado('Test por temas');
        setTipoTestPersonalizado('t');
        setMetodoCorreccion('');
        setTemasSeleccionados([]);
        setMinPreguntasTestPersonalizado(10);
        setNumPreguntasTestPersonalizado(10);
        setMaxPreguntasTestPersonalizado(100);
        setVistaSeleccionTemas(true);
      };

      const testAleatorio = () => {
        setTituloModalPersonalizado('Test aleatorio');
        setTipoTestPersonalizado('r');
        setMetodoCorreccion('');
        setMinPreguntasTestPersonalizado(10);
        setNumPreguntasTestPersonalizado(10);
        setMaxPreguntasTestPersonalizado(100);
        setModalpersonalizarVisible(true);
      };

      const testErrores = () => {
        if(contadorPreguntasFalladas === 0){
            toast.current.show({ severity: 'info', summary: 'Error', detail: 'Actualmente no tiene preguntas falladas' });
        } else {
            setTituloModalPersonalizado('Test de errores');
            setTipoTestPersonalizado('e');
            setMetodoCorreccion('');
            if(contadorPreguntasFalladas < 10){
                setMinPreguntasTestPersonalizado(contadorPreguntasFalladas);
                setNumPreguntasTestPersonalizado(contadorPreguntasFalladas);
            } else {
                setMinPreguntasTestPersonalizado(10);
                setNumPreguntasTestPersonalizado(10);
            }
            if(contadorPreguntasFalladas > 100){
                setMaxPreguntasTestPersonalizado(100);
            } else {
                setMaxPreguntasTestPersonalizado(contadorPreguntasFalladas);
            }
            setModalpersonalizarVisible(true);
        }
      };

      if(listaTests === null){

        api.post(restUrlBase + "listaTests.php", {  })
        .then(response => {
  

            if (response.data.success) {
                setListaTests(response.data.listaTests);
                setListaControles(response.data.controles);
                setContadorPreguntasFalladas(response.data.contadorPreguntasFalladas);
                setGruposTematicos(response.data.gruposTematicos);
                setTemas(response.data.temas);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
            }
           
        })
        .catch(error => {
           toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        });

        return (
            <>
                <Toast ref={toast} />
                <div className="card flex justify-content-center loading">
                    <ProgressSpinner />
                </div>
            </>
        );
    } else {
        if(loading){
            return (
                <div className="card flex justify-content-center loading">
                    <ProgressSpinner />
                </div>
            );
        } else {

                return (
                    <>
                    <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{backgroundImage: `url(${cabecera})`}}>  
                        <Container fluid> 
                            <Row className="align-items-center">
                                <Col sm="12">
                                    <nav className="text-center iq-breadcrumb-two">
                                        <h2 className="title">ZONA DE TEST</h2>
                                    </nav>
                                </Col>
                            </Row> 
                        </Container>
                     </div>
                    <div>
                        <Toast ref={toast} />
                        
                        <Container fluid>
                            <Row>
                                <Col sm="12" className={!vistaSeleccionTemas ? 'd-none' : 'd-block'}>
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between mt-4">
                                            <Card.Header.Title>
                                                <h4 className="main-title">Personalizar test por temas</h4>
                                            </Card.Header.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row className='mb-4 mt-4'>
                                                <button type='button' className='btn btn-hover iq-button' onClick={volverVistaGeneral}><i className="fa fa-backward mr-1" aria-hidden="true"></i>Volver</button>
                                            </Row>
                                            <Row>
                                                <Col sm="12" lg="8" className='mt-4 p-4'>
                                                    {gruposTematicos.map((grupo) => {
                                                        return (
                                                            
                                                            <Row key={grupo.grupoTematico}>
                                                                <h5 className="card-title">{grupo.descripcion}</h5>
                                                                {temas.filter(
                                                                    (tema) => tema.grupoTematico === grupo.grupoTematico
                                                                )
                                                                .map((tema) => {
                                                                    return (
                                                                        <Col sm="12" key={tema.id}>
                                                                            <Checkbox inputId={'tema-' + tema.ID} name="temas" value={tema.ID} onChange={onTemasSeleccionadosChange} checked={temasSeleccionados.includes(tema.ID)} />
                                                                            <label htmlFor={'tema-' + tema.ID} className="ml-2">{tema.nombre}</label>
                                                                        </Col>
                                                                    )
                                                                })}
                                                            </Row>
                                                        );
                                                    })}
                                                </Col>
                                                
                                                <Col sm="12" lg="4" className='text-center mt-4 p-4'>
                                                    <ConfiguradorTest minPreguntas={10} maxPreguntas={100} stepsPreguntas={1} tiempo={null} permitirAyuda={true} onNumPreguntasChange={setNumPreguntasTestPersonalizado} onMetodoCorreccionChange={setMetodoCorreccion} onTiempoChange={setTiempoTest} onAyudaChange={setModoAyuda} onSinTiempoChange={setSinTiempo}/>
                                                    <div className='form-group'>
                                                        <button type='button' className='btn btn-hover iq-button mt-4' onClick={personalizar}><i className="fa fa-send mr-1" aria-hidden="true"></i>Empezar</button>
                                                    </div>
                                                </Col>
                                           </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm="12" className={vistaSeleccionTemas ? 'd-none' : 'd-block'}>
                                    <Dialog header={tituloModalPersonalizado} visible={modalPersonalizarVisible} onHide={() => setModalpersonalizarVisible(false)} footer={(
                                        <><button type='button' className='btn btn-hover iq-button' onClick={personalizar}><i className="fa fa-send mr-1" aria-hidden="true"></i>Empezar</button></>
                                          )}>
                                        <div className='row d-flex flex-column text-center p-4'>
                                        <ConfiguradorTest minPreguntas={minPreguntasTestPersonalizado} maxPreguntas={maxPreguntasTestPersonalizado} stepsPreguntas={1} tiempo={null} permitirAyuda={true} onNumPreguntasChange={setNumPreguntasTestPersonalizado} onMetodoCorreccionChange={setMetodoCorreccion} onTiempoChange={setTiempoTest} onAyudaChange={setModoAyuda} onSinTiempoChange={setSinTiempo}/>
                                            {tipoTestPersonalizado === 'g' && (<>
                                                <div className='form-group'>
                                                    <label>Grupo temático</label>
                                                </div>
                                                <div className='form-group'>
                                                    <ListBox value={grupoTematico} onChange={(e) => setGrupoTematico(e.value)} options={gruposTematicos} optionLabel="descripcion" className="w-full md:w-14rem" />
                                                </div></>
                                            )}
                                            {tipoTestPersonalizado === 'e' && (
                                                <div className='form-group'>
                                                    <label>Nº de preguntas falladas actualmente</label>
                                                    <div className='h1'>{contadorPreguntasFalladas}</div>
                                                </div>
                                            )}
                                        </div>
                                </Dialog>
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between mt-4">
                                            <Card.Header.Title>
                                                <h4 className="main-title">Tests personalizados</h4>
                                            </Card.Header.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="row d-flex justify-content-between">
                                                <div className='col-xs-12 col-md-3 d-flex flex-column'>
                                                    <button type='button' className='btn btn-hover iq-button mt-2' onClick={testGrupoTematico}><i className="fa fa-bars mr-1" aria-hidden="true"></i>Test por grupo temático</button>
                                                    <button type='button' className='btn btn-hover iq-button mt-2' onClick={testTemas}><i className="fa fa-book mr-1" aria-hidden="true"></i>Test por temas</button>
                                                    <button type='button' className='btn btn-hover iq-button mt-2' onClick={testAleatorio}><i className="fa fa-random mr-1" aria-hidden="true"></i>Test aleatorio</button>
                                                    <button type='button' className='btn btn-hover iq-button mt-2' onClick={testErrores}><i className="fa fa-times mr-1" aria-hidden="true"></i>Test de errores</button>
                                                </div>
                                                <div className='col-xs-12 col-md-9'>
                                                    Evolución de los tests realizados
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                
                                {listaControles != null && listaControles.length > 0 && (
                                <>
                                <Col sm="12" className={vistaSeleccionTemas ? 'd-none' : 'd-block'}>
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between mt-4">
                                            <Card.Header.Title>
                                                <h4 className="main-title">Controles disponibles</h4>
                                            </Card.Header.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="table-view">
                                                <table className="data-tables table movie_table " style={{width:"100%"}}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width:"50%"}}>Nombre</th>
                                                            <th style={{width:"20%"}}>Disponible hasta</th>
                                                            <th style={{width:"10%"}}>Duración</th>
                                                            <th style={{width:"20%"}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {listaControles.map((control, indexControl) => {
                                                        return (
                                                            <tr key={indexControl}>
                                                                <td>{control.test_nombre}</td>
                                                                <td>{control.fecha_fin_test}</td>
                                                                <td>{control.tiempo_minutos} minutos</td>
                                                                <td>
                                                                {control.fecha_inicio_alumno == null && (
                                                                    <button type='button' className='btn btn-hover iq-button w-100' onClick={() => hacerControl(control)}><i className="fa fa-cog mr-1" aria-hidden="true"></i>Empezar</button>
                                                                )}
                                                                {control.fecha_inicio_alumno != null && (
                                                                    <button type='button' className='btn btn-hover iq-button w-100 mt-1' onClick={() => hacerControl(control)}><i className="fa fa-cog mr-1" aria-hidden="true"></i>Reanudar</button>
                                                                )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                </>
                                )}
                                
                                <Col sm="12" className={vistaSeleccionTemas ? 'd-none' : 'd-block'}>
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between mt-4">
                                            <Card.Header.Title>
                                                <h4 className="main-title">Últimos tests disponibles</h4>
                                            </Card.Header.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="table-view">
                                                <table className="data-tables table movie_table " style={{width:"100%"}}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width:"60%"}}>Nombre</th>
                                                            <th style={{width:"10%"}}>Fecha</th>
                                                            <th style={{width:"10%"}}>Nota</th>
                                                            <th style={{width:"20%"}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {listaTests.map((test, indexTest) => {
                                                        return (
                                                            <tr key={indexTest}>
                                                                <td>{test.test_nombre}</td>
                                                                <td>{test.test_fecha}</td>
                                                                <td>{test.nota_fecha}<br/>{test.nota}</td>
                                                                <td>
                                                                {test.descargable && (
                                                                    <button type='button' className='btn btn-hover iq-button w-100' onClick={() => descargar(test)}><i className="fa fa-download mr-1" aria-hidden="true"></i>Descargar</button>
                                                                )}
                                                                {test.online && (
                                                                    <button type='button' className='btn btn-hover iq-button w-100 mt-1' onClick={() => hacer(test)}><i className="fa fa-cog mr-1" aria-hidden="true"></i>Hacer online</button>
                                                                )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
            
                    </div>
                    </>
                )
                    
            }

    
        }
}
export default ListaTests;