import React,{ useState} from 'react'
import { useRef } from 'react';
import { gsap } from 'gsap'
import { Link } from 'react-router-dom'
import { Container,Col,Row,Nav,Tab, } from 'react-bootstrap'
import FsLightbox from 'fslightbox-react';
import Select from 'react-select'
import api from '../../../interceptor/axios';

// img
import icon from '../../../assets/video/trailer.mp4'

// favorite img
import fav1 from '../../../assets/images/favorite/01.jpg'

// parallax
import parallax3 from '../../../assets/images/parallax/p1.jpg'
import parallax4 from '../../../assets/images/parallax/parallax-logo.png'

//skeleton
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';

//axios
import axios from 'axios'

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade,Navigation,Thumbs,Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([EffectFade,Navigation,Thumbs,Pagination]);



const gsapAnimate = {
   getData: (elem) => {
      const option = {
         opacity: 0,
         scale: 1,
         position: {
               x: 0,
               y:0,
         },
         ease: "",
         duration: 1,
         delay: .4,
         rotate: 0
      }
      if(elem !== undefined) {
         option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)

         option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)

         option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)

         option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)

         option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)

         option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)

         option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)

         option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')

         const setOption = {opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay}

         return setOption
      } else {
         return {opacity: 0}
      }
   },
   onStart : (elem) => {
      
      const setOption = gsapAnimate.getData(elem)

      gsap.from(elem, setOption)

   },

   onEnd : (elem) => {
      
      const setOption = gsapAnimate.getData(elem)
      
      gsap.to(elem, setOption)

   },

   onStartEnd : (elem) => {

      const setOption = gsapAnimate.getData(elem)

      const setEndOption = gsapAnimate.getData(elem)

      setEndOption.opacity = 1

      setEndOption.x = 0

      setEndOption.y = 0

      setEndOption.rotate = 0

      setEndOption.scale = 1

      gsap.fromTo(elem, setOption, setEndOption)
   },
   validValue: (attr, defaultVal) => {
      if (attr !== undefined && attr !== null) {
         return Number(attr)
      }
      return Number(defaultVal)
   }
}

const Dashboard =({oposicion})=>{
   const [toggler1, setToggler1] = useState(false);
   const [toggler2, setToggler2] = useState(false);
   const [toggler3, setToggler3] = useState(false);
  
   const toast = useRef(null);
   const [grupos, setGrupos] = useState(null);
   const [temas, setTemas] = useState(null);
   const [permisos, setPermisos] = useState(null);

   const tienePermiso = (idTema) => {
      if(idTema === null || permisos === null) return -1;

      return permisos.indexOf(idTema) !== -1;
   };
 
    const irATema = (tema) => {
      //comprobamos si el ID_tema esta en la lista de permisos
      if(tienePermiso(tema.ID) === -1){
         toast.current.show({ severity: 'error', summary: 'Error', detail: 'No tienes permisos para acceder a este tema' });
         return;
      }
      sessionStorage.setItem('idTema',tema.ID);
      window.location.href = '/tema';
    };   

   if(temas === null){

      api.post(process.env.REACT_APP_REST_URL + 'temario.php', { idOposicion: oposicion })
      .then(response => {

          if(response.data.success !== true){
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
          } else {
            setGrupos(response.data.gruposTematicos);
            setTemas(response.data.temas);
            setPermisos(response.data.permisos);
         }
      })
      .catch(error => {
         toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
      });


      return (<>
         <Toast ref={toast} />
         <div className="skeleton-temario">
            <section id="iq-favorites">
               <Container fluid>
                  <Row>
                     <Col sm="12" className="overflow-hidden">
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                     </Col>
                     <Col sm="12" className="overflow-hidden">
                        <div className="d-flex align-items-end justify-content-between w-100">
                              <Skeleton width="18rem" height="10rem"></Skeleton>
                              <Skeleton width="18rem" height="10rem"></Skeleton>
                              <Skeleton width="18rem" height="10rem"></Skeleton>
                              <Skeleton width="18rem" height="10rem"></Skeleton>
                        </div>
                     </Col>
                  </Row>
                  <Row>
                     <Col sm="12" className="overflow-hidden mt-4">
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                     </Col>
                     <Col sm="12" className="overflow-hidden">
                        <div className="d-flex align-items-end justify-content-between w-100">
                              <Skeleton width="18rem" height="10rem"></Skeleton>
                              <Skeleton width="18rem" height="10rem"></Skeleton>
                              <Skeleton width="18rem" height="10rem"></Skeleton>
                              <Skeleton width="18rem" height="10rem"></Skeleton>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </section>
         </div>
      </>);
   }

   return(
      <>
         <Toast ref={toast} />
         <FsLightbox
               toggler={toggler1}
               sources={[
                  <iframe src={icon} title=" " width="500px" height="200px"
                     frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
               ]}
         />  
         <FsLightbox
            toggler={toggler2}
            sources={[
               <iframe src={icon} title=" " width="500px" height="200px"
                  frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
            ]}
         />
         <FsLightbox
            toggler={toggler3}
            sources={[
               <iframe src={icon} title=" " width="500px" height="200px"
                  frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
            ]}
         />

      
         
         <div className="main-content">
            
         {grupos.map((grupo, index) => {
          return (
            <section key={index}>
              <Container fluid>
            <Row>
                <Col sm="12" className="overflow-hidden">
                <div className="d-flex align-items-center justify-content-between">
                    <h4 className="main-title">{grupo.descripcion}</h4>
                </div>
                <div id="favorites-contens">
                    <div id={'prev' + index} className="swiper-button swiper-button-prev"><i className= "fa fa-chevron-left"></i></div>
                    <div id={'next' + index} className="swiper-button swiper-button-next"><i className= "fa fa-chevron-right"></i></div>
                    <Swiper  
                        navigation={{
                            prevEl: '#prev' + index,
                            nextEl: '#next' + index
                        }} 
                        breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 }
                        }}
                        loop={true}  
                        slidesPerView={4} 
                        spaceBetween={20}  
                        as="ul" 
                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">

                        {temas
                           .filter(tema => tema.grupo_tematico === grupo.ID)
                           .map((tema, indexTema) => (
                              
                            <SwiperSlide as="li" key={indexTema}>
                            <div className=" block-images position-relative">
                                <div className="img-box">
                                    <img src={tema.imagen != '' ? process.env.REACT_APP_ESTATICOS_URL + "portadas/" + tema.ID + '.jpg' : fav1} className="img-fluid portada-tema" alt=""/>
                                </div>
                                {tienePermiso(tema.ID) && 
                                    <div className="block-description">
                                          <h6 className="iq-title enlace" onClick={() => irATema(tema)}>{tema.nombre}</h6>
                                          
                                             <div className="hover-buttons">
                                                <button type='button' className='btn btn-hover iq-button' onClick={() => irATema(tema)}><i className="fa fa-play mr-1" aria-hidden="true"></i>Empezar</button>
                                             </div>
                                    </div>
                                 } 
                                 {!tienePermiso(tema.ID) && 
                                    <div className="block-description">
                                          <h6 className="iq-title enlace">{tema.nombre}</h6>
                                    </div>
                                 }
                            </div>
                        </SwiperSlide> 
                                
                        ))}
                    </Swiper>
                </div>
                </Col>
            </Row>
        </Container>
            </section>
          );

      })}

         </div>
      </>
   )
}


export default Dashboard