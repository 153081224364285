import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_REST_URL, // Reemplaza esto con la URL de tu API
});

// Agrega un interceptor de solicitud a Axios
api.interceptors.request.use(
  (config) => {
    // Recupera el token del sessionStorage
    const token = sessionStorage.getItem('token');
    // Agrega el token al encabezado de autorización de la solicitud
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Agrega un interceptor de respuesta a Axios
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {

    if(error.response.status === 403){
      window.location.href = '/403.html';
    } else if(error.response.status === 401){
      sessionStorage.clear();
      window.location.reload();
    }
  }
);

export default api;
