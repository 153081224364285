export const Texto = ({ bloque }) => {

    return (
        <>
            <p className={bloque.datos.align}>{bloque.datos.texto}</p>
        </>
    );

};

export default Texto;