import React,{ useState,useRef,useEffect, Component} from 'react'     
import { ProgressSpinner } from 'primereact/progressspinner';  
import { Toast } from 'primereact/toast';      
import {Container,Row,Col,OverlayTrigger,Tooltip} from 'react-bootstrap';
import Card  from '../../../components/Card'
import { Badge } from 'primereact/badge';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import api from '../../../interceptor/axios';

class Temporizador extends Component {
    constructor(props) {
      super(props);
      let segundosRestantes = props.segundos;
      //pasamos a minutos y segundos
        let minutos = Math.floor(segundosRestantes / 60);
        let segundos = segundosRestantes - minutos * 60;
      this.state = {
        minutos: minutos,
        segundos: segundos
      };
    }
  
    componentDidMount() {
      this.intervalId = setInterval(this.actualizarTiempo, 1000);
    }
  
    componentWillUnmount() {
      clearInterval(this.intervalId);
    }
  
    actualizarTiempo = () => {
      let { minutos, segundos } = this.state;
      const { onTiempoAgotado } = this.props;
    
      if (minutos === 0 && segundos === 0) {
        clearInterval(this.intervalId);
        if(onTiempoAgotado){
            onTiempoAgotado();
        }
      } else {
        segundos--;
        if(segundos < 0){
            segundos = 59;
            minutos--;
        }
        if(minutos < 0){
            minutos = 0;
        }
        this.setState({ minutos: minutos, segundos: segundos });
      }
    };
  
    render() {
      const { minutos, segundos } = this.state;
  
      return (
        <h3 className="slider-text big-title title text-uppercase mr-4">
        {minutos.toString().padStart(2, '0')}:{segundos.toString().padStart(2, '0')}
      </h3>
      );
    }
  }
  




const SelectorPregunta = ({preguntas, indiceActual, mostrarAvisos, notifyPreguntaActual}) => {

    const [preguntaSeleccionada, setPreguntaSeleccionada] = useState(preguntas[indiceActual]);
    const cambioPregunta = (indexPregunta) => {
        setPreguntaSeleccionada(preguntas[indexPregunta]);
        notifyPreguntaActual(indexPregunta);
    };

    return (<>
        <div className='selectorPreguntas d-none d-lg-block'>
            {preguntas.map((pregunta, indexPregunta) => {
                const enunciadoRecortado = pregunta.texto.length > 20 ? pregunta.texto.substring(0, 20) + '...' : pregunta.texto;
                let clase = '';
                if(indexPregunta == indiceActual){
                    clase = ' actual';
                } else {
                    if(pregunta.estado === 'correcta'){
                        clase = ' correcta';
                    } else if(pregunta.estado === 'erronea'){
                        clase = ' erronea';
                    } else if(pregunta.respuestaSeleccionada !== 0){
                        clase = ' respondida';
                    } else {
                        clase = ' no-contestada';
                    }
                }
                return (
                    <div className="pregunta d-flex justify-content-start mb-1" key={indexPregunta} onClick={() => cambioPregunta(indexPregunta)}>
                        {mostrarAvisos && pregunta.impugnaciones.length > 0 && (
                            <i className='fa fa-warning icono-pregunta-impugnada mr-1 mt-1'></i>
                        )}
                        <div className={'orden' + clase}>
                            <Badge value={pregunta.orden} className='orden-pregunta'></Badge>
                        </div>
                        <div className='enunciado ml-4'>
                            {enunciadoRecortado}
                        </div>
                    </div>
                );
            })}
        </div>
        <div className='selectorPreguntas d-block d-lg-none text-center'>
            <Dropdown value={preguntaSeleccionada} onChange={(e) => cambioPregunta(e.value.orden - 1)} options={preguntas} optionLabel='texto' placeholder='Selecciona una pregunta' className='w-full' />
        </div>
        </>
    );
};

const Pregunta = ({pregunta, respuestaMarcada, notifyCambioRespuesta}) => {

    const [respuestaSeleccionada, setRespuestaSeleccionada] = useState(respuestaMarcada);
    const cambioRespuesta = (orden) => {
        setRespuestaSeleccionada(orden);
        notifyCambioRespuesta(orden);
    }

    useEffect(() => {
        setRespuestaSeleccionada(pregunta.respuestaSeleccionada);
    }, [pregunta.respuestaSeleccionada]);

    return (
        <>
            {
                pregunta.imagen !== null && pregunta.imagen !== '' && (
                    <Card className="card p-4 mb-4">
                        <Card.Body className="ml-4 mt-4">
                            <img src={pregunta.imagen} className="img-fluid" />
                            <p className="mt-4 card-title">{pregunta.textoImagen}</p>
                        </Card.Body>
                </Card>
                )
            }
            <Card className="card p-4">
                    <Card.Header>
                        <Card.Header.Title>
                            <h4 className="card-title">{(pregunta.orden) + '. ' + pregunta.texto}</h4>
                        </Card.Header.Title>
                    </Card.Header>
                    <Card.Body className="ml-4 mt-4">
                    {pregunta.respuestas.map((respuesta, indexRespuesta) => {
                        let clase = '';
                        //convertimos pregunta.respuestaCorrecta en un numero entero
                        let respuestaCorrecta = parseInt(pregunta.respuestaCorrecta);
                        if(pregunta.estado === 'correcta' && indexRespuesta + 1  === respuestaCorrecta){
                            clase = ' correcta';
                        } else if(pregunta.estado === 'erronea' && indexRespuesta + 1 === pregunta.respuestaSeleccionada){
                            clase = ' erronea';
                        } else if((pregunta.estado === 'erronea' || pregunta.estado === 'no-contestada') && indexRespuesta + 1 === respuestaCorrecta){
                            clase = ' correcta';
                        }

                        return (
                            <div key={indexRespuesta} className="d-flex align-items-left mb-2">
                            <RadioButton
                                disabled={pregunta.estado !== 'activa'}
                                inputId={indexRespuesta}
                                name="respuesta"
                                value={respuesta.orden}
                                onChange={(e) => cambioRespuesta(e.value)}
                                checked={respuestaSeleccionada === respuesta.orden}
                            />
                            <label htmlFor={indexRespuesta} className={'ml-2' + clase}>
                                {respuesta.texto}
                            </label>
                            </div>
                        );
                    })}
                    </Card.Body>
                </Card>
        </>
    );

};

const Botonera = ({modoAyudaActivado, ordenActual, numPreguntas, testFinalizado, notifyAnteriorPregunta, notifySiguientePregunta, notifyCorregirPregunta, corregirTest}) => {

    return (
        <div className="d-flex justify-content-begin mt-4">
                {ordenActual > 0 && (
                    <button type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={notifyAnteriorPregunta}><i className="fa fa-backward mr-1" aria-hidden="true"></i>Anterior</button>
                )}
                {ordenActual < numPreguntas - 1 && (
                    <button type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={notifySiguientePregunta}><i className="fa fa-forward mr-1" aria-hidden="true"></i>Siguiente</button>
                )}
                {modoAyudaActivado && !testFinalizado && (
                    <button type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={notifyCorregirPregunta}><i className="fa fa-question mr-1" aria-hidden="true"></i>Corregir pregunta</button>
                )}
                {!testFinalizado && (
                    <button type='button' className='d-none d-lg-block btn btn-hover iq-button ml-4' onClick={corregirTest}><i className="fa fa-edit mr-1" aria-hidden="true"></i>Terminar y corregir</button>
                )}
          </div>
    );
};

const Test = () => { 

    if(sessionStorage.getItem('test') === null){
        window.location.href = '/tests';
    }

    const restUrlBase = process.env.REACT_APP_REST_URL;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const [indiceActual, setIndiceActual] = useState(0);
    const [datos, setDatos] = useState(JSON.parse(sessionStorage.getItem('test')));
    const [correctas, setCorrectas] = useState(0);
    const [erroneas, setErroneas] = useState(0);
    const [noContestadas, setNoContestadas] = useState(0);
    const [preguntaActual, setPreguntaActual] = useState(datos.preguntas[0]);
    const [testFinalizado, setTestFinalizado] = useState(datos.finalizado);
    const [nota, setNota] = useState(null);
    let segundosRestantes = null;
    if(datos.tiempo !== undefined){
        segundosRestantes = datos.tiempo * 60; //el tiempo viene en minutos
        //calculamos los segundos que han pasado desde datos.fechaInicio hasta ahora
        let fechaInicio = new Date(datos.fechaInicio);
        let fechaActual = new Date();
        let segundosTranscurridos = Math.round((fechaActual - fechaInicio) / 1000);
        //restamos los segundos transcurridos al tiempo total del test
        segundosRestantes = segundosRestantes - segundosTranscurridos;
    } 

    datos.preguntas.forEach((pregunta, indexPregunta) => {
        if(pregunta.respuestaSeleccionada === undefined){
            pregunta.respuestaSeleccionada = 0;
        }
        if(pregunta.estado === undefined){
            pregunta.estado = 'activa';
        }

    });

    if(datos.finalizado === undefined){
        datos.finalizado = false;
        setTestFinalizado(false);
    }

    useEffect(() => {
        datos.nota = nota;
        datos.finalizado = testFinalizado;
        sessionStorage.setItem('test', JSON.stringify(datos));
    }, [testFinalizado, nota]);

    useEffect(() => {
        setPreguntaActual(datos.preguntas[indiceActual]);
      }, [indiceActual, datos.preguntas]);

    useEffect(() => {
        if((correctas + erroneas + noContestadas) === datos.preguntas.length){
            corregirTest();
        }
    }, [correctas, erroneas, noContestadas]);

    const cambioPregunta = (indexPregunta) => {
        setIndiceActual(indexPregunta);
    }; 

    const cambioRespuesta = (indexRespuesta) => {
        let datosActualizados = datos;
        datosActualizados.preguntas[indiceActual].respuestaSeleccionada = indexRespuesta;
        setDatos(datosActualizados);
        sessionStorage.setItem('test', JSON.stringify(datosActualizados));
    };

    const anteriorPregunta = () => {
        if(indiceActual > 0){
            setIndiceActual(indiceActual - 1);
        }
    };

    const siguientePregunta = () => {
        if(indiceActual < datos.preguntas.length - 1){
            setIndiceActual(indiceActual + 1);
        }
    };

    const marcarPreguntaFallada = (idPregunta, fallada) => {
        api.post(restUrlBase + "test/preguntaFallada.php", { idPregunta: idPregunta, fallada: fallada })
            .then(response => {
                
                if (response.data.success == false) {
                    console.log("error al marcar pregunta fallada");
                } 
                
            }
            ).catch(error => {
                console.log("error al marcar pregunta fallada");
                //este error es silencioso para no cortar el flujo de trabajo
            }
        );
    };

    const resultadoPregunta = (pregunta) => {
        if(pregunta.respuestaSeleccionada === pregunta.respuestaCorrecta){
            setCorrectas(correctas + 1);
            marcarPreguntaFallada(pregunta.ID, 'N');
            return 'correcta';
        } else if(pregunta.respuestaSeleccionada !== 0) {
            setErroneas(erroneas + 1);
            marcarPreguntaFallada(pregunta.ID, 'S');
            return 'erronea';
        } else {
            setNoContestadas(noContestadas + 1);
            return 'no-contestada';
        }
    };

    const corregirPregunta = () => {
        if(preguntaActual.estado === 'activa'){
            let datosActualizados = datos;
            let resultado = resultadoPregunta(preguntaActual);
            datosActualizados.preguntas[indiceActual].estado = resultado;
            setDatos(datosActualizados);
        }
    };

    const corregirTest = () => {
        setLoading(true);
        let datosActualizados = datos;
        datosActualizados.preguntas.forEach((pregunta, indexPregunta) => {
            if(pregunta.estado === 'activa'){
                pregunta.estado = resultadoPregunta(pregunta);
            }
        });
        setDatos(datosActualizados);

        //calculo de nota
        let nota = 0;
        let penalizacion = 2;
        if(datos.metodo_correccion === "2-1"){
            let penalizacion = 2;
        } else if(datos.metodo_correccion === "3-1"){
            let penalizacion = 3;
        } else if(datos.metodo_correccion === "4-1"){
            let penalizacion = 4;
        }
        let puntuacion = (correctas - (erroneas / penalizacion)) / datos.preguntas.length * 10;
        if(puntuacion < 0){
            puntuacion = 0;
        }
        puntuacion = Math.round((puntuacion + Number.EPSILON) * 100) / 100;
        setNota(puntuacion);
        setTestFinalizado(true);
        if(datos.ID !== 0 && datos.ID !== undefined){
            //guardamos nota en el servidor
            api.post(restUrlBase + "test/guardarNota.php", { idTest: datos.ID, nota: puntuacion })
            .then(response => {
                setLoading(false);

                if (response.data.success) {
                    toast.current.show({ severity: 'success', summary: 'Nota guardada correctamente', detail: '' });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
                }
                
            })
            .catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
            });
        } else {
            //pausa dramatica para crear suspense
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    };

    if(loading){
        return (
            <div className="card flex justify-content-center loading">
                <ProgressSpinner />
            </div>
        );
    } else {
        return (<>
            <Toast ref={toast} />
            <div className="main-content m-4">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <div className="border-round border-1 surface-border p-4 surface-card d-flex">
                                {segundosRestantes && testFinalizado == false && (
                                    <Temporizador segundos={segundosRestantes} onTiempoAgotado={corregirTest}/>
                                )}       
                                <div>
                                    <h3 className="slider-text big-title title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">{datos.test_nombre}</h3>
                                    <h3 className="slider-text big-title title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">{datos.test_fecha}</h3>
                                </div> 
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={3}>
                            <SelectorPregunta preguntas={datos.preguntas} indiceActual={indiceActual} mostrarAvisos={testFinalizado} notifyPreguntaActual={cambioPregunta} />
                        </Col>
                        <Col sm={12} lg={8}>
                            <div>
                                {nota !== null && (
                                            <h4 className="slider-text big-title title text-uppercase mt-4 mb-4" data-iq-gsap="onStart" data-iq-position-x="-200">{'Tu nota: ' + nota}</h4>
                                        )} 
                                <Pregunta pregunta={preguntaActual} respuestaMarcada={preguntaActual.respuestaSeleccionada} notifyCambioRespuesta={cambioRespuesta} />
                                <Botonera modoAyudaActivado={datos.modoAyuda && preguntaActual.estado === 'activa'}  ordenActual={indiceActual} numPreguntas={datos.preguntas.length} testFinalizado={testFinalizado} notifyAnteriorPregunta={anteriorPregunta} notifySiguientePregunta={siguientePregunta} notifyCorregirPregunta={corregirPregunta} corregirTest={corregirTest} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>);
    }
} 
export default Test; 