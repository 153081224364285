//control de login
import React, { useState } from 'react';
//router
import LayoutsRoute from './router/layouts-route';
//prime theme y core
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css"; 

//prime icons
import 'primeicons/primeicons.css';


//scss files
import './assets/css/bootstrap.min.css'
import './assets/css/typography.css'
import './assets/css/style.css';
import './assets/css/responsive.css'


// import  './assets/css/custom.css';

function App() {
  

  return (
    <div className="App">
      <LayoutsRoute />
    </div>
  );
}

export default App;
