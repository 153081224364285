import React,{ useState,useRef} from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

import { Toast } from 'primereact/toast';
import axios from 'axios'
import api from '../../../interceptor/axios';

import Titulo from '../../../components/leccion/titulo';
import Texto from '../../../components/leccion/texto';
import ListaPuntos from '../../../components/leccion/listaPuntos';
import Fichero from '../../../components/leccion/fichero';
import Pregunta from '../../../components/leccion/pregunta';
import { ProgressSpinner } from 'primereact/progressspinner'; 

const Leccion = () => {
    const restUrlBase = process.env.REACT_APP_REST_URL;
    const toast = useRef(null);
    const [datos, setDatos] = useState(null);
    const [loading, setLoading] = useState(false);
    const idLeccion = sessionStorage.getItem('idLeccion');

    const descargar = (idBloque) => {
        //sacamos el id usuario del token jwt
        const token = sessionStorage.getItem('token');

        axios.post(restUrlBase + "descargarPDF.php", { id: idBloque, tipo: 'B' }, { responseType: 'arraybuffer', headers: {
            'Authorization': 'Bearer ' + token} 
        })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
      
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'test.pdf');
      
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
          });
      };

    if(idLeccion === null){
        window.location.href = '/404';
    }

    if(datos === null){
        api.post(restUrlBase + "leccion.php", { idLeccion: idLeccion })
        .then(response => {
            if (response.data.success) {
                setDatos(response.data);
             } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message });
             }
        })
        .catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se ha producido un error en la llamada al servidor' });
        });

        return (
            <>
                <Toast ref={toast} />
                <div className="card flex justify-content-center loading">
                    <ProgressSpinner />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{backgroundImage: `url(${process.env.REACT_APP_URL_PORTADAS + datos.leccion.imagen})`}}>  
                    <Container fluid> 
                        <Row className="align-items-center">
                            <Col sm="12">
                                <nav className="text-center iq-breadcrumb-two">
                                    <h2 className="title">{datos.leccion.nombre}</h2>
                                </nav>
                            </Col>
                        </Row> 
                    </Container>
                </div>
                <main id="main" className="site-main">
                    <Container>
                        <Row>
                            <Col>
                            {
                                datos.bloques.map((bloque, index) => {
                                    if (bloque.datos.tipo === "titulo") {
                                        return <Titulo key={index} bloque={bloque} />;
                                    } else if (bloque.datos.tipo === "texto") {
                                        return <Texto key={index} bloque={bloque} />;
                                    } else if (bloque.datos.tipo === "listaPuntos") {
                                        return <ListaPuntos key={index} bloque={bloque} />;
                                    } else if (bloque.datos.tipo === "fichero") {
                                        return <Fichero key={index} bloque={bloque} descargar={descargar} slug={datos.leccion.slug} />;
                                    } else if (bloque.datos.tipo === "pregunta"){
                                        return <Pregunta key={index} pregunta={bloque.datos} />;
                                    }
                                })
                                }
                            </Col>
                        </Row>
                    </Container>
                </main>
            </>
        )
    }
    


}

export default Leccion;